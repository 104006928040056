import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableContainer = makeShortcode("TableContainer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section maxWidth="small" mdxType="Section">
      <p><strong parentName="p">{`Effective Date: September 1st, 2022`}</strong></p>
      <p>{`This Rasa Support Services Policy is the primary document used to communicate Rasa's support policies to licensees of Rasa software products (each such licensee, a "Customer"), including Rasa's software products available under both proprietary licenses and open source licenses (collectively, "Software"). As referenced either in the proprietary license agreement under which you obtain and are granted the right to use Rasa's software and/or the agreement under which you obtain a subscription ("Subscription") to Rasa's support services (in each case, the "Agreement"), this Support Services Policy sets forth Rasa's support terms and conditions, as well as provides a description of Rasa's technical support levels. Capitalized terms not defined herein have the definition set forth in the applicable Agreement.`}</p>
      <h3 {...{
        "id": "1-scope-of-support-services"
      }}>{`1. Scope of Support Services`}</h3>
      <p>{`The scope of the Support Services provided to Customer includes general assistance and support regarding the installation of the Software and basic technical configuration of the Software, as well as developer assistance on how to use the Software. Rasa will provide Support Services to Customer in accordance with this Support Services Policy and the Agreement.`}</p>
      <h3 {...{
        "id": "2-support-services-offerings"
      }}>{`2. Support Services Offerings`}</h3>
      <p>{`Rasa offers two different levels of the Support Services which are described below in Section 3 of this Support Services Policy, and are subject to the specific terms and conditions set forth therein regarding hours of operation, response times, methods of support and other matters for each Subscription Level. Rasa shall use commercially reasonable efforts to meet the applicable targeted response times set forth below. Customer acknowledges that the time required for resolution of issues may vary depending on the specific circumstances of each problem, including, without limitation, the nature of the incident/problem, the extent and accuracy of information available about the incident/problem, and the level of Customer's cooperation and responsiveness in providing materials, information, access and support reasonably required by Rasa to achieve problem resolution. Rasa addresses problem resolutions through a number of mechanisms.`}</p>
      <h3 {...{
        "id": "3-support-levels"
      }}>{`3. Support Levels`}</h3>
      <p>{`All Support requests must be entered  in our `}<a parentName="p" {...{
          "href": "https://support.rasa.com/"
        }}>{`support portal`}</a>{` or via `}<a parentName="p" {...{
          "href": "mailto:support@rasahq.zendesk.com"
        }}>{`support@rasahq.zendesk.com`}</a>{` .
The submission needs to be prior to engaging a Rasa team member. Once the appropriate support request is filed, the most optimal method of contact may be utilized (phone, web, email), subject to the applicable Subscription Level.
Defined Terms
"Business Day" means Monday through Friday other than a day designated from time to time as a national holiday in the place from which Support Services may be provided.
"Business Hour" means any hour between 9-5 local time of a Business Day.
"Incident" means a single question or issue posed by a Support Contact using the support portal.
"Support Contact" means a single named individual that is authorized to contact Rasa to make use of the support portal.`}</p>
      <TableContainer mdxType="TableContainer">
  <table>
    <thead>
      <tr>
        <th />
        <th width="28%">Standard Support - Reactive Support</th>
        <th width="28%">Premium Support - Proactive Support</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <strong>Deployment Option</strong>
        </td>
        <td>Self-Managed</td>
        <td>Self-Managed or Rasa-as-a-service by Rasa</td>
      </tr>
      <tr>
        <td>
          <strong>Support Hours</strong>
        </td>
        <td>Business hours support</td>
        <td>24/7/365 support</td>
      </tr>
      <tr>
        <td>
          <strong>Support Channel</strong>
        </td>
        <td>Web-based support</td>
        <td>Web-based support, Conference Calls</td>
      </tr>
      <tr>
        <td>
          <strong>Response times</strong>
        </td>
        <td>
          Support response time SLAs
          <br />
          Urgent: 4 hours
          <br />
          High: 1 business day
          <br />
          Normal: 2 business days
        </td>
        <td>
          Enhanced support response time SLAs
          <br />
          Urgent: 1 hour
          <br />
          High: 4 hours
          <br />
          Normal: 1 business day
        </td>
      </tr>
      <tr>
        <td>
          <strong># incidents</strong>
        </td>
        <td>Unlimited</td>
        <td>Unlimited</td>
      </tr>
      <tr>
        <td>
          <strong># customer contacts</strong>
        </td>
        <td>2</td>
        <td>8</td>
      </tr>
    </tbody>
  </table>
      </TableContainer>
      <p>{`Rasa will use commercially reasonable efforts to meet the targeted response times above. Time required for resolution of issues may vary depending on the specific circumstances of each problem, including, without limitation, the nature of the incident or problem, the extent and accuracy of information available about the incident or problem, and the level of Licensee’s cooperation and responsiveness in providing materials, information, access and support reasonably required by Rasa to achieve problem resolution.`}</p>
      <h3 {...{
        "id": "4-severity-level-definitions"
      }}>{`4. Severity Level Definitions`}</h3>
      <p><strong parentName="p">{`Urgent`}</strong>{` `}<br />{`
A Urgent severity level issue is either an incident with Customers’ chatbot in production, or a major error within the software that severely impacts the Customer's use of the software for production purposes, such as the loss of production data or where production systems are not functioning and no work-around exists. Rasa will use continuous efforts during the Normal Hours of Operation stated above for the applicable Subscription level to provide a resolution for any Urgent issues as soon as is commercially reasonable.`}</p>
      <p><strong parentName="p">{`High`}</strong>{` `}<br />{`
A High severity level issue is either an incident with Customers’ chatbot in production, or an error within the software where the customer's system is functioning for production purposes but in a reduced capacity, such as a problem that is causing significant impact to portions of the customer's business operations and productivity, or where the software is exposed to potential loss or interruption of service. Rasa will use continuous efforts during the Normal Hours of Operation stated above for the applicable Subscription level to provide a resolution for any High severity level issues.`}</p>
      <p><strong parentName="p">{`Normal`}</strong>{` `}<br />{`
A Normal severity level  issue is either an incident with Customers’ chatbot in production, or a medium-to-low impact error that involves partial and/or non-critical loss of functionality for production purposes or development purposes, such as a problem that impairs some operations but allows the customer's operations to continue to function. Errors for which there is limited or no loss or functionality or impact to the customer's operation and for which there is an easy work-around qualify as a Normal severity level. Rasa will use reasonable efforts to provide a resolution for any Normal issue  in time for an upcoming release of Software.`}</p>
      <h3 {...{
        "id": "5-customer-obligations"
      }}>{`5. Customer Obligations`}</h3>
      <p>{`Customer must provide a functional Digital Assistant description at time of a support request to clearly define the Digital Assistant Rasa will be supporting. Customer is responsible for ensuring that its personnel that interact with Rasa have sufficient English language and technical skills, and respond to and cooperate with Rasa in a timely manner in connection to requests for Support Services.
As a precondition for requesting Support Services from Rasa, Customer (A) must have a Rasa software subscription, and (B) agrees to (and to cause each of its developers to) use reasonable efforts to: (i) attempt to solve the problem and to utilize sufficient resources to clearly understand that a problem exists before consulting Rasa; (ii) provide Rasa with sufficient information and technical data in order for Rasa to establish that a potential problem is not the kind of problem that is an exclusion from the Support Services; (iii) make reasonable attempts and expend reasonable resources to provide any data reasonably requested by Rasa to adequately address the potential problem; (iv) utilize sufficient resources to understand the instructions from Rasa in addressing the problem, and make reasonable attempts to correct the problem as suggested by Rasa. In addition, Customer agrees and acknowledges that the extent of access and the accuracy of information and technical data provided may affect Rasa's ability to provide the Support Services.
CUSTOMER AGREES NOT TO USE THE SUPPORT SERVICES IN CONNECTION WITH THE DEVELOPMENT, DEPLOYMENT, ENABLEMENT AND/OR MAINTENANCE OF ANY NON-RASA SOFTWARE THAT COMPETES WITH RASA’S COMMERCIAL SOFTWARE PRODUCTS.`}</p>
      <h3 {...{
        "id": "6-support-service-exclusions"
      }}>{`6. Support Service Exclusions`}</h3>
      <p>{`Rasa will have no obligation to provide Support Services to Customer in the event that (i) the Software has been changed, modified or damaged by Customer or anyone other than Rasa, (ii) the problem is caused by Customer's negligence, misconduct, or misuse of the Software, a hardware malfunction, or other causes beyond the reasonable control of Rasa, (iii) the problem is due to third party software, (iv) the Software is being hosted by a third party that is offering the Software as a service (v) Customer has not installed or implemented any Software releases made generally available or is not running a then supported version of the Software as provided by Rasa as defined in the `}<a parentName="p" {...{
          "href": "https://rasa.com/rasa-product-release-and-maintenance-policy"
        }}>{`Rasa Product Release and Maintenance Policy`}</a>{` or (vi) information requested by Customer could reasonably be expected to assist in the development, deployment, enablement and/or maintenance of any non-Rasa software that competes with Rasa's commercial software products. The Support Services do not cover the support of any third party software which integrates with the Software or the investigation into a potential or actual security incident in a Customer environment, including but not limited to the analysis and response to security events and signals. In addition, the Support Services do not include the following: (a) use of any version of a Software that is not designated as a production release (such as a preview release or release candidate or code contained in the sandbox or any other repository that is not packaged into a production release distribution); (b) Customer's failure to comply with operating instructions contained in the documentation; (c) installation, configuration, management and operation of Customer's digital assistants; (d) APIs, interfaces or data formats other than those included with the Software; or (e) any training. `}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      